import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5b94cec2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TtClaim" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_Unit = _resolveComponent("Unit")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 2
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            (_ctx.form.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "汇款编号",
                            prop: "code"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[0] || (_cache[0] = e => _ctx.form.code = _ctx.valid(e)),
                                modelValue: _ctx.form.code,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.code) = $event)),
                                placeholder: "请输入请输入汇款编号",
                                maxlength: "20",
                                disabled: true,
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "汇款人名称",
                            prop: "name"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[2] || (_cache[2] = e => _ctx.form.name = _ctx.valid(e)),
                                modelValue: _ctx.form.name,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.name) = $event)),
                                placeholder: "请输入汇款人名称",
                                maxlength: "50",
                                disabled: true,
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "汇款人账号",
                            prop: "hkAccount"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[4] || (_cache[4] = e => _ctx.form.hkAccount = _ctx.valid(e)),
                                modelValue: _ctx.form.hkAccount,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.hkAccount) = $event)),
                                placeholder: "请输入汇款人账号",
                                maxlength: "25",
                                disabled: true,
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "汇款国家",
                            prop: "countryId"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                ref: el=>_ctx.refMap.set('countryId',el),
                                modelValue: _ctx.form.countryId,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.countryId) = $event)),
                                clearable: "",
                                placeholder: "请选择汇款国家",
                                style: {"width":"100%"},
                                disabled: true,
                                onChange: _cache[7] || (_cache[7] = (val)=>{_ctx.selectOnChange(val,'countryId')})
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'countryId'}), (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value,
                                      disabled: item.disabled
                                    }, null, 8, ["label", "value", "disabled"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "币种",
                            prop: "bz"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                ref: el=>_ctx.refMap.set('bz',el),
                                modelValue: _ctx.form.bz,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.bz) = $event)),
                                placeholder: "请选择币种",
                                style: {"width":"100%"},
                                disabled: true,
                                onChange: _cache[9] || (_cache[9] = (val)=>{_ctx.selectOnChange(val,'bz')})
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'bz'}), (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value,
                                      disabled: item.disabled
                                    }, null, 8, ["label", "value", "disabled"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "汇入金额",
                            prop: "hrAmount"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Unit, {
                                modelValue: _ctx.form.hrAmount,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.hrAmount) = $event)),
                                unit: _ctx.otherParams.unit,
                                disabled: true,
                                min: 0,
                                max: 9999999
                              }, null, 8, ["modelValue", "unit"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "收款人账号",
                            prop: "skAccount"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[11] || (_cache[11] = e => _ctx.form.skAccount = _ctx.valid(e)),
                                modelValue: _ctx.form.skAccount,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.skAccount) = $event)),
                                placeholder: "请输入收款人账号",
                                maxlength: "25",
                                disabled: true,
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "收款银行",
                            prop: "bankId"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, {
                                ref: el=>_ctx.refMap.set('bankId',el),
                                modelValue: _ctx.form.bankId,
                                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.bankId) = $event)),
                                clearable: "",
                                placeholder: "请选择收款银行",
                                style: {"width":"100%"},
                                disabled: true,
                                onChange: _cache[14] || (_cache[14] = (val)=>{_ctx.selectOnChange(val,'bankId')})
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'bankId'}), (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.value,
                                      label: item.label,
                                      value: item.value,
                                      disabled: item.disabled
                                    }, null, 8, ["label", "value", "disabled"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 12 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "交易日期",
                            prop: "date"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_date_picker, {
                                modelValue: _ctx.form.date,
                                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.date) = $event)),
                                placeholder: "请选择交易日期",
                                disabled: true
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: "备注",
                            prop: "remark"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                onInput: _cache[16] || (_cache[16] = e => _ctx.form.remark = _ctx.valid(e)),
                                modelValue: _ctx.form.remark,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.remark) = $event)),
                                type: "textarea",
                                placeholder: "请输入备注",
                                maxlength: "250",
                                disabled: true,
                                clearable: ""
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "客户",
                      prop: "customerId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('customerId',el),
                          modelValue: _ctx.form.customerId,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.customerId) = $event)),
                          clearable: "",
                          placeholder: "请选择客户",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[19] || (_cache[19] = (val)=>{_ctx.selectOnChange(val,'customerId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'customerId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "实收金额",
                      prop: "amount"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Unit, {
                          modelValue: _ctx.form.amount,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.amount) = $event)),
                          unit: _ctx.otherParams.unit,
                          disabled: _ctx.disabled,
                          min: 0,
                          max: 9999999
                        }, null, 8, ["modelValue", "unit", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "手续费",
                      prop: "sxf"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Unit, {
                          modelValue: _ctx.sxf,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.sxf) = $event)),
                          unit: _ctx.otherParams.unit,
                          disabled: true,
                          min: 0,
                          max: 9999999
                        }, null, 8, ["modelValue", "unit"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}